import React, { useEffect, useMemo, useRef } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Rate } from 'antd';
import { LandingContentInfoModel } from '@/types/home';

const CustomersTalkUsContent = ({ contentInfo }: { contentInfo?: LandingContentInfoModel }) => {
  const { t } = useTranslation('common');
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const scrollStep = 1;
    const scrollInterval = 30;

    let isUserScrolling = false;

    const scroll = () => {
      if (!isUserScrolling) {
        container.scrollLeft += scrollStep;
        if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
          container.scrollLeft = 0;
        }
      }
    };

    const handleTouchStart = () => {
      isUserScrolling = true;
    };

    const handleTouchEnd = () => {
      isUserScrolling = false;
    };

    // 添加触摸事件监听器
    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchend', handleTouchEnd);

    const intervalId = setInterval(scroll, scrollInterval);

    // 清理定时器和事件监听器
    return () => {
      clearInterval(intervalId);
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const getContent = useMemo(() => {
    return contentInfo?.title?.length > 0 ? (
      <div className={styles.content}>
        {contentInfo?.title && <h2>{contentInfo?.title}</h2>}

        {contentInfo?.desc && <p className={styles.titleDesc}>{contentInfo?.desc}</p>}

        <div className={styles.container} ref={containerRef}>
          {contentInfo?.cards?.map((item, index) => {
            return (
              <div className={styles.cardContainer} key={index + item.name}>
                <div className={styles.userContainer}>
                  <Image
                    src={item?.imgPath ?? ''}
                    alt={item?.name}
                    className={styles.icon}
                    width={46}
                    height={46}
                  />
                  <span className={styles.userName}>{item?.name}</span>
                </div>

                <div className={styles.line} />

                <ul className={styles.starContainer}>
                  <Rate
                    className={styles.star}
                    allowHalf
                    disabled
                    defaultValue={Number(item?.points)}
                    value={Number(item?.points)}
                  />
                  <span className={styles.date}>{item?.date}</span>
                </ul>
                <h3 className={styles.desc}>{item?.desc}</h3>
              </div>
            );
          })}
        </div>
      </div>
    ) : null;
  }, [contentInfo]);

  return <>{getContent}</>;
};

export default CustomersTalkUsContent;
